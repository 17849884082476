import React, { Component } from 'react';
import API from '../../utils/API';
import { Result, Button, Modal} from 'antd';
import {Link, withRouter } from 'react-router-dom';
import ReactPlayer from "react-player";
import './css/coursedetails.css';
import './css/breadcrumb.css';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
import LogoImage from '../../assets/images/logo.png';
import {Helmet} from "react-helmet";
let Vimeo = require('vimeo').Vimeo;
//let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");

class CourseDetail extends Component{
   constuctor() {
      this.selectActive = this.selectActive.bind(this);
    }
   state ={
      pageid: this.props.pageid,
      courseData:[],
      initial: 0,
      accesscheck: '',
      hideredirectmodal: false,
      membershiprole: 'free',
      hidesubscriptiontmodal: false,
      level:0 ,
      myplayer: [],
    }
    componentDidMount() {
      this.getData();
      this.start = this.start.bind(this);
      this.next = this.next.bind(this);
      this.prev = this.prev.bind(this);
      this.finish = this.finish.bind(this);
      this.handleRedirect = this.handleRedirect.bind(this);
      this.cancleRedirect = this.cancleRedirect.bind(this);
      this.cancleRedirecttoPricing = this.cancleRedirecttoPricing.bind(this)
      //this.selectActive = this.selectActive.bind(this);
    };
    getData(){
      API.get('/courses/contents/node/'+this.state.pageid)
      .then(result => {
         //setCourseData(result.data.courses);
         if(result.data.courses[0].status == "true"){
            let videofileitem = [];
            this.setState({accesscheck: 'pass'});
            this.setState({courseData: result.data.courses});
            result?.data?.courses[0]?.videos.map(videoitem =>{
               /* client.request({
                  method: 'GET',
                  path: '/videos/'+videoitem.videoURL
                  }, function (name,embed) {
                  console.log('data: ',embed);
                  if(embed?.files){
                     embed?.files.map(file =>{
                        var link1080p = null;
                        var link720p = null;
                        var link540p = null;
                        var link360p = null;
                        var link240p = null;
                        if(file.quality == 'hd' || file.quality == 'sd'){
                           if(file.rendition = "1080p"){
                              link1080p = file.link;
                           }else if(file.rendition = "720p"){
                              link720p = file.link;
                           }else if(file.rendition = "540p"){
                              link540p = file.link;
                           }else if(file.rendition = "360p"){
                              link360p = file.link;
                           }else if(file.rendition = "240p"){
                              link240p = file.link;
                           }
                        }
                        link1080p ? 
                        videofileitem[videoitem.videoURL]={
                           'file':link1080p,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                        :link720p ? 
                        videofileitem[videoitem.videoURL]={
                           'file':link720p,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                        :link540p ? 
                        videofileitem[videoitem.videoURL]={
                           'file':link540p,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                        :link360p ? 
                        videofileitem[videoitem.videoURL]={
                           'file':link360p,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                        :link240p ? 
                        videofileitem[videoitem.videoURL]={
                           'file':link240p,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                        :videofileitem[videoitem.videoURL]={
                           'file':embed?.files[0].link,
                           'preview':embed?.pictures?.base_link,
                           'privacy':"private"
                        }
                     })
                  }else{
                     videofileitem[videoitem.videoURL]={
                        'file':embed?.link,
                        'preview':embed?.pictures?.base_link,
                        'privacy':"public"
                     }
                    }
               }) */
               
            })
            this.state.myplayer =videofileitem;
            if (localStorage.getItem('token') && localStorage.getItem('user')) {
               let id = JSON.parse(localStorage.getItem('user')).id;
               API.get('users/'+id).then(result=>{
                 this.setState({level:JSON.parse(localStorage.getItem('user')).level});
                 if(result.data != null && result.data.user.membershiprole != 'undefined'){
                   this.setState({membershiprole: result.data.user.membershiprole});
                 }
               }).catch(e =>{
                 console.log(e.message);
               });
            }
         }else{
            this.setState({accesscheck: 'fail'})
         }
         
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
    start(){
      if(localStorage.getItem('token')){
         if(this.state.level == 1){
            this.setState({initial: this.state.initial+1});
         }else if(this.state.courseData[0].visibilityoption == 'paid' && this.state.membershiprole == 'free'){
            this.setState({hidesubscriptiontmodal:true});
          }else{
            this.setState({initial: this.state.initial+1});
          }
      }else{
        this.setState({hideredirectmodal:true});
      }
      window.scrollTo(140, 0);
   }
    next(){
       if(localStorage.getItem('token')){
         if(this.state.level == 1){
            this.setState({initial: this.state.initial+1});

            
          }else if(this.state.courseData[0].visibilityoption == 'paid' && this.state.membershiprole == 'free'){
            this.setState({hidesubscriptiontmodal:true});
          }else{
            this.setState({initial: this.state.initial+1});
          }
       }else{
         this.setState({hideredirectmodal:true});
       }
       window.scrollTo({
         top: 0, 
         behavior: 'smooth'
       });
    }
    prev(){
      if(localStorage.getItem('token')){
         if(this.state.level == 1){
            this.setState({initial: this.state.initial-1});
         }else if(this.state.courseData[0].visibilityoption == 'paid' && this.state.membershiprole == 'free'){
            this.setState({hidesubscriptiontmodal:true});
          }else{
            this.setState({initial: this.state.initial-1});
          }
       }else{
         this.setState({hideredirectmodal:true});
       }
      window.scrollTo({
         top: 0, 
         behavior: 'smooth'
       });
    }
    finish(){
       this.props.history.push('/education/videocourses');
    }
    selectActive = (e) => {
       if(localStorage.getItem('token')){
         if(this.state.level == 1){
            this.setState({initial:Number(e.currentTarget.dataset.id)})
         }else if(this.state.courseData[0].visibilityoption == 'paid' && this.state.membershiprole == 'free' && Number(e.currentTarget.dataset.id)){
            this.setState({hidesubscriptiontmodal:true});
          }else{
            this.setState({initial:Number(e.currentTarget.dataset.id)})
          }
       }else{
          if(e.currentTarget.dataset.id != '0'){
            //let path = '/login?='+this.state.courseData[0].pathalias.alias;
            //this.props.history.push(path);
            this.setState({hideredirectmodal:true});
          }
       }
       window.scrollTo(140, 0);
    }
    handleRedirect (){
       this.setState({hideredirectmodal: false});
       this.setState({hidesubscriptiontmodal: false});
       if(localStorage.getItem('token')){
         let path = '/pricing?='+this.state.courseData[0].pathalias.alias;
         this.props.history.push(path);
       }else{
         let path = '/login?='+this.state.courseData[0].pathalias.alias;
         this.props.history.push(path);
       }
       
    }
    cancleRedirecttoPricing(){
      this.setState({hideredirectmodal: false});
      this.setState({hidesubscriptiontmodal: false});
      let path = '/pricing';
      this.props.history.push(path);
    }
    cancleRedirect(){
      this.setState({hideredirectmodal: false});
      this.setState({hidesubscriptiontmodal: false});
    }
    render(){
       if(this.state.accesscheck == 'fail'){
         return <Result
         status="404"
         title="404"
         subTitle="Ooops, la página que visitaste no existe."
         extra={
            <Button type="primary">
               <Link to="/">Volver a la página de inicio</Link>
            </Button>
         }
         style={{ margin: '20px 0' }}
         />
       }
      const redirectModal = () => {
         return <Modal
            visible={this.state.hideredirectmodal}
            okText="Iniciar sesión"
            onOk={this.handleRedirect}
            onCancel={this.cancleRedirecttoPricing}
            cancelText="Suscríbete"
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div className="emptymessage" >Debes iniciar sesión para ver este video</div>
         </Modal>
      }
      const subscriptionUpgradeModal = () => {
         return <Modal
            visible={this.state.hidesubscriptiontmodal}
            okText="Actualizar membresía"
            onOk={this.handleRedirect}
            onCancel={this.cancleRedirect}
            cancelText="Cancelar"
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Necesita actualizar su membresía para ver todos los videos</div>
         </Modal>
      }
      const renderNavButtons = () => {
         if(this.state.courseData[0].videos.length == 1){
            return ;
         }else if (this.state.initial == 0) {
           return <div className="navButtons"><button onClick={this.start}>COMIENZA A APRENDER</button></div>;
         }else if(this.state.courseData[0].videos.length-1 == this.state.initial ){
            return <div className="navButtons"><button onClick={this.prev}>previo</button><button onClick={this.finish}>Explore más cursos</button></div>;
         } else {
           return <div className="navButtons"><button onClick={this.prev}>previo</button><button onClick={this.next}>Siguiente</button></div>;
         }
       }
       /* const renderTags = () => {
          if(this.state.courseData[0].keywords != ""){
            return <div className="taglist"> <span>Etiquetas:</span><ul>
               {this.state.courseData[0].keywords.split(',').map(tag =>
                  <li><Link to={"/education/videocourses/tags/"+tag.trim("").replace(/\s+/g, '-')}>{tag}</Link></li>
               )}
            </ul></div>
          }
       } */
      return (
         <div className="coursedetialspage">
            {this.state.courseData.map(course =>
               <div>
                  <div className="breadcrumbwrapper">
                     <div className="breadcrumb">
                        <ul>
                           <li><Link to='/education'>Educacion</Link></li>
                           <li><Link to="/education/videocourses">ENTRENAMIENTOS</Link></li>
                           <li>{course.title}</li>
                        </ul>
                     </div>
                  </div>
                  <Helmet>
                     <meta charSet="utf-8" />
                     <link rel="canonical" href="https://comunidad.enlacancha.us/" />
                     <title>{course.title+(' | En La Cancha Social')}</title>
                     <meta name="description" content={course?.body?.replace(/<[^>]+>/g, '')} />
                     <meta property="og:title" content="En La Cancha Social" />
                     <meta property="og:url" content={window.location.href} />
                     <meta property="og:type" content="video.movie" />
                     <meta property="og:image" content={LogoImage} />
                     <meta property="og:description" content={course?.body?.replace(/<[^>]+>/g, '')} />
                     <meta property="og:title" content={course.title} />
                  </Helmet>
                  <div className="coursevideopagerlist">
                     <div className="viewcontent thumbnails progress-container">
                        <ul>
                        {course.videos.sort((a, b) => a?.videoPosition > b?.videoPosition ? 1 : -1).map((video, index) => {
                           if(this.state.initial == index){
                              return <li onClick={this.selectActive} id={"row-"+(index)} data-id={index} className="viewrow active" title={video.videoProgressTitle}>{video.videoProgressTitle}</li>
                           }else if(index < this.state.initial){
                              return <li onClick={this.selectActive} id={"row-"+(index)} data-id={index} className="viewrow completed" title={video.videoProgressTitle}>{video.videoProgressTitle}</li>
                           }else{
                              return <li onClick={this.selectActive} id={"row-"+(index)} data-id={index} className="viewrow" title={video.videoProgressTitle}>{video.videoProgressTitle}</li>
                           }
                        })}
                        </ul>
                     </div>
                  </div>

                  {course.videos.slice(this.state.initial, this.state.initial+1).map((video, index) =>{
                     return <div className="courseintroductionwrapper">
                           <div className="courseintroduction">
                              <h2 className="title">{video.videoTitle}</h2>
                              <div className="description">
                                 {video.videoDescription&&<div dangerouslySetInnerHTML={ { __html:video.videoDescription}} ></div>}
                              </div>
                              {/* { course.selectedCategory.length > 0 && (
                              <div className="category"><label>Categoría: </label>
                                 <span>{course.selectedCategory[0].name}</span>
                              </div>
                              )}
                              */}
                           </div>
                     </div>          
                  })} 

                  
                  <div className="coursedetailsview">
                     <div className="coursevideosliderview">
                        <div className="viewcontent slider">
                           {course.videos.slice(this.state.initial, this.state.initial+1).map((video, index) =>{
                              return <div id={"row"+(index+1)+' '+(video.videoURL)} className="viewrow" key={"row"+(index+1)}>
                                 <div className="coursevideo" key={index}>
                                    {/* {this.state.myplayer[video.videoURL]?.privacy == "private"
                                    ?
                                    <video key={video.videoURL} controls autoPlay width="100%" loop poster={this?.state?.myplayer[video.videoURL]?.preview}>
                                       <source src={this?.state?.myplayer[video.videoURL]?.file} type="video/mp4"/>
                                    <p>If you can read this, your browser does not support the audio element.</p>
                                    </video>
                                    : */}
                                    <ReactPlayer
                                    url={'https://vimeo.com/'+video.videoURL}
                                       width="100%"
                                       height="558px"
                                       playing
                                       
                                       controls={true}
                                       />
                                    {/* } */}
                                    
                     
{/* {this?.state?.videofilelink != '' &&                          
   <video controls autoPlay width="100%" loop poster={this?.state?.videopreviewpicture}>
      <source src={this?.state?.videofilelink} type="video/mp4"/>
   <p>If you can read this, your browser does not support the audio element.</p>
   </video>
}    */}                
                                 
                                 </div>
                                 <div className="courseshare">
                                    <ul>
                                       {/* <li className="bookmark"><a href="#">bookmark</a></li> */}
                                       <li className="share">
                                          <SocialShareBlock shareTitle={course.title} shareUrl={window.location.href} />
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="course-detail-wrapper">
                                    
                                    {video.videoKeyTakeaway && (
                                       <div className="coursecontent">
                                          <h2 className="blocktitle">Conclusiones clave</h2>
                                          <div className="viewcontent">
                                                <div className="description">
                                                   <div dangerouslySetInnerHTML={ { __html:video.videoKeyTakeaway}} ></div>
                                                </div>
                                          </div>
                                       </div>
                                    )}
                                    <div className="coursecontent">
                                       { this.state.initial == 0 && (
                                       <div>                       
                                       <h2 className="blocktitle">Contenido del curso</h2>
                                       <div className="viewcontent-accordion">
                                          <div className="course-detail description">
                                             {course.body &&<div dangerouslySetInnerHTML={ { __html:course.body}} ></div>}
                                          </div>
                                          <ul className="video-course-item">
                                             {course.videos.sort((a, b) => a?.videoPosition > b?.videoPosition ? 1 : -1).map((video, index) => {
                                                   return <li onClick={this.selectActive} id={"row-"+(index)} key={"row-"+(index)} data-id={index} className="viewrow active">{video.videoTitle}</li>
                                                
                                             })}
                                             </ul>
                                          {/* <Collapse 
                                             className="viewcontent"
                                             bordered={false}
                                             expandIconPosition="right"
                                             
                                             expandIcon={({ isActive }) => isActive ?
                                             (<UpOutlined/>)
                                             :(<DownOutlined/>)
                                             }
                                             >
                                                {course.videos.map((video, index) =>
                                                   <Panel 
                                                      header= {video.videoTitle}
                                                      key={index}
                                                      >
                                                   <div className="contentbody videodescriptionlist">
                                                      <div className="videodescriptioncontent">
                                                         <div className="videodescriptionrow">
                                                            <div className="description">
                                                               {video.videoDescription}
                                                            </div>
                                                         </div>
                                                      </div>
                                                      {<div className="totaltimes">total {video.videoDuration}</div>}
                                                   </div>
                                                </Panel>
                                                )}
                                          </Collapse> */}
                                       </div>
                                       </div>
                                       )}
                                    </div>
                                 </div>
                              </div>          
                           })} 
                        </div>
                     </div>
                     {renderNavButtons()}
                     {/* {renderTags()} */}
                     {redirectModal()}
                     {subscriptionUpgradeModal()}
                  </div>
               </div>
            )}
         </div>
      );
    }
}

export default withRouter(CourseDetail);